<template>
    <div>
        <ViewTitle
            :title="`業績報表 | 單位業績查詢`"
            class="viewtitle-container"
        />
        <IntervalFilter
            :listData="listData"
            :stylerList="stylerList"
            :isLoading="isTableLoading"
            @show-data-table="showDataTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
        />
        <SearchCondition
            :dataList="searchConditionList"
            :isShowTable="isTableLoading"
            :isHasListData="listData.length > 0"
            :isLoading="false"
            class="interval-condition"
        />
        <IntervalTable
            :bgName="bgName"
            :listData="listData"
            :filterLog="filterLog"
            :stylerList="stylerList"
            :licenseList="licenseList"
            :performanceList="performanceList"
            :isLoading="isTableLoading"
            :infos="searchConditionList"
        />
        <MobileAlertModal v-model="isShowMobileAlert" />
        <div v-if="loadWaitingModal">
            <WaitingModal
                v-model="isShowWaitingModal"
                :isLoading="isTableLoading"
                :isSuccess="!isTableLoading"
            />
        </div>
    </div>
</template>

<script>
// import _ from 'lodash'
import { lazyLoadComponent } from '@/assets/javascripts/util'

const WaitingModal = lazyLoadComponent('containers/performance/WaitingModal')
import SearchCondition from '@/components/SearchCondition.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import IntervalFilter from '@/containers/report/IntervalFilter.vue'
import IntervalTable from '@/containers/report/IntervalTable.vue'
import MobileAlertModal from '@/containers/report/MobileAlertModal.vue'
import { getReportListIntervalAPI } from '@/assets/javascripts/api'

export default {
    name: 'ReportInterval',
    components: {
        ViewTitle,
        IntervalFilter,
        IntervalTable,
        MobileAlertModal,
        WaitingModal,
        SearchCondition
    },
    methods: {
        showDataTable: async function () {
            if (!this.filterPayload.manCode) return
            /* Get Table Data */
            try {
                this.isShowWaitingModal = true
                this.isTableLoading = true
                this.loadWaitingModal = true
                this.isShowWaitingModal = true

                let response = await this.$getResponse(
                    this.filterPayload,
                    this.permission,
                    getReportListIntervalAPI
                )
                this.listData = response.data.member
                this.dataProcess(response.data)
            } catch (error) {
                this.listData = []
            } finally {
                this.isTableLoading = false
            }
        },
        dataProcess: function (data) {
            const rootStyle = {
                root: {
                    skipRowAbove: 1
                }
            }
            const range = data?.header?.reportInfo?.performance?.range
            this.performanceList = data?.performance || {}
            this.licenseList = data?.licence || {} // BE: licence FE: license
            this.stylerList = Object.assign(rootStyle, data?.styler || {})
            this.bgName = data?.header?.name || ''
            this.startDate = range?.startDate.substring(0, 10) || ''
            this.endDate =
                data?.header?.calculationEndDate?.substring(0, 10) || ''
            this.endDate = this.$formatDate(
                this.$getDate(0, 0, 0, this.endDate)
            )
        }
    },
    computed: {
        searchConditionList: function () {
            const dataType = this.filterLog?.dataType?.value
            let dateItem = []
            const receive = {
                title: '受理日區間',
                value: `${this.filterLog?.receiveDateStart?.label || '-'} 至 ${
                    this.filterLog?.receiveDateEnd?.label || '-'
                }`
            }
            const commit = {
                title: '核實月區間',
                value: `${
                    this.filterLog?.commitDateStart?.label?.slice(0, 7) || '-'
                } 至 ${
                    this.filterLog?.commitDateEnd?.label?.slice(0, 7) || '-'
                }`
            }
            const effect = {
                title: '生效日區間',
                value: `${this.filterLog?.effectDateStart?.label || '-'} 至 ${
                    this.filterLog?.effectDateEnd?.label || '-'
                }`
            }
            const isAudit = {
                title: '審核狀態',
                value: this.filterLog?.isAudit?.label || '-'
            }

            if (dataType === 1) {
                dateItem = [receive, isAudit]
            } else if (dataType === 2) {
                dateItem = [commit]
            } else {
                dateItem = [receive, effect, commit]
            }
            return dateItem
        },
        listenShowModal: function () {
            return {
                mobileAlert: this.isShowMobileAlert,
                waiting: this.isShowWaitingModal
            }
        },
        permission: function () {
            return {
                apiAlias: 'list',
                apiName: '列表'
            }
        }
    },
    watch: {
        listenShowModal: {
            handler() {
                const listen = this.listenShowModal
                const body = document.querySelector('body')
                if (!listen.waiting && listen.mobileAlert) {
                    setTimeout(() => {
                        this.$addAllClass(body, 'not-scroll')
                    }, 500)
                }
            },
            deep: true
        }
    },
    data() {
        return {
            isTableLoading: false,
            isShowMobileAlert: false,
            isShowWaitingModal: false,
            isLoadingTable: false,
            tableShowData: [],
            listData: [],
            performanceList: {},
            licenseList: {},
            stylerList: {},
            bgName: '',
            startDate: '',
            endDate: '',
            pathName: '',
            filterPayload: {
                titleType: '',
                manCode: '',
                role: '',
                bgCode: '',
                endDate: '',
                relationMode: ''
            },
            filterLog: {},
            loadWaitingModal: false
        }
    },
    async created() {
        this.isShowMobileAlert = this.$isMobile() && !this.$isMobileHorizontal()
    }
}
</script>

<style lang="scss" scoped>
.interval-condition {
    display: none;
    @media screen and (max-width: 576px) {
        display: block;
    }
}
</style>
